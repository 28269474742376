import { DEFAULT_COOKIE_DOMAIN_SETTINGS } from "constants/index";
import { COOKIE_AUTH } from "constants/index";
import Cookies from "js-cookie";

export const logout = () => {
  Cookies.remove(COOKIE_AUTH, DEFAULT_COOKIE_DOMAIN_SETTINGS);
  setTimeout(() => {
    window.location.href = `${process.env.REACT_APP_BAIMS_URL}/login?url_redirect=https://${window.location.host}`;
  }, 100);
};
